import React from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';

import { container, btn, label, icon } from './QuantityButton.module.scss';

const QuantityButton = ({ quantity, add, subtract }) => {
  return (
    <div className={container}>
      <button className={`${btn}`} type="button" onClick={subtract}>
        <FiMinus className={icon} />
      </button>
      <label className={label}>{quantity}</label>
      <button className={`${btn}`} type="button" onClick={add}>
        <FiPlus className={icon} />
      </button>
    </div>
  );
};

export default QuantityButton;
