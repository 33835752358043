import React from 'react';

import Ailo from 'components/Icons/Ailo';

import * as styles from './Copyrights.module.scss';

const Copyrights = ({ site }) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div>
          Copyright © {new Date().getFullYear()} {site}
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;All Rights Reserved
        </div>
        <div className={styles.brand}>
          <span className={styles.text}>Designed & Developed by </span>
          <a
            href="https://ailogram.com"
            aria-label="Designed & Developed by Ailogram"
            target="_blank"
            rel="noreferrer"
          >
            <Ailo fill="#fff" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Copyrights;
