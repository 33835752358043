import * as React from 'react';

function SvgAilo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.6 69.2" {...props}>
      <path fill="#ee2a7b" d="M25.1 55l8.4 13.9h33V55z" />
      <path fill="#b21865" d="M52.9 55l13.6 13.9V55z" />
      <path
        fill="#2e3192"
        d="M66.5 68.9l8.2-13.6L41.5 0 0 69.2h16.4l25.1-41.9z"
      />
      <path
        d="M112.1 25.8c-2.9-2.2-6.5-3.3-10.7-3.3-4.4 0-8.5 1.7-11.7 4.9-3.1 3.1-4.8 7.3-4.8 11.7 0 4.4 1.7 8.6 4.8 11.7 3.1 3.1 7.3 4.9 11.7 4.9 4.1 0 7.3-.9 9.7-2.8v1.6h6.2V24.3l-3.7 2.8c-.5-.4-1-.8-1.5-1.3zm-10.7 23.7C95.6 49.3 91 44.8 91 39.1s4.6-10.4 10.3-10.4c6.6 0 9.5 4.1 9.7 6.7v6.4c0 2.1-.8 3.9-2.1 5.2-1.6 1.7-4.2 2.5-7.5 2.5zm21.5-25.2h6.1v30.1h-6.1zm3-8.7c-1.9 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6-1.6-3.6-3.6-3.6zm8.6-.3h6.2v39.1h-6.2zm26.7 7.2c-4.4 0-8.5 1.7-11.7 4.9-3.1 3.1-4.9 7.3-4.9 11.7 0 4.4 1.7 8.5 4.9 11.7 3.1 3.1 7.3 4.9 11.7 4.9 4.4 0 8.5-1.7 11.7-4.9 3.1-3.1 4.9-7.3 4.9-11.7 0-4.4-1.7-8.5-4.9-11.7-3.2-3.1-7.3-4.9-11.7-4.9zm0 27c-5.7 0-10.4-4.7-10.4-10.4s4.7-10.4 10.4-10.4 10.4 4.7 10.4 10.4-4.6 10.4-10.4 10.4zm35.9-27c-9 0-16.5 7.6-16.5 16.5s7.6 16.5 16.5 16.5c4 0 7.2-.9 9.6-2.7-.8 4.9-5.1 8.6-10.2 8.6-.8 0-1.6-.1-2.6-.4l-1.7-.4V67l1.2.2c1.1.2 2.2.3 3.2.3 8 0 15-6.1 16.3-13.8l.2-29.6-3.9 2.8c-3.3-2.9-7.2-4.4-12.1-4.4zm.1 27c-5.9-.2-10.4-4.7-10.4-10.4s4.7-10.4 10.4-10.4c6.5 0 9.4 4.1 9.7 6.7v6.4c0 2.1-.8 3.9-2.1 5.2-1.8 1.7-4.4 2.5-7.6 2.5zm27.7-24.9v-.8h-6.1v30.7h6.1v-21c0-1.2.5-2.2 1.3-2.9.9-.6 1.6-.9 2.6-.7l1.7.3v-6.4h-1.4c-1.7-.1-3 .2-4.2.8zm33.2 1.2c-2.9-2.2-6.5-3.3-10.7-3.3-4.4 0-8.5 1.7-11.7 4.9-3.1 3.1-4.8 7.3-4.8 11.7 0 4.4 1.7 8.6 4.8 11.7 3.1 3.1 7.3 4.9 11.7 4.9 4.1 0 7.3-.9 9.7-2.8v1.6h6.1V24.3l-3.7 2.8c-.4-.4-.9-.8-1.4-1.3zm-10.7 23.7c-5.8-.2-10.4-4.7-10.4-10.4s4.6-10.4 10.3-10.4c6.6 0 9.5 4.1 9.7 6.7v6.4c0 2.1-.8 3.9-2.1 5.2-1.6 1.7-4.2 2.5-7.5 2.5zm70-22.8c-2.2-2.3-5.6-3.6-10-4-.2 0-.6-.1-2.3-.1-5 .2-8.5 1.6-10.6 4.1-.1.1-.2.2-.2.3-.1-.1-.2-.2-.3-.4-2.5-2.5-6.2-3.9-11.1-4.1-.2 0-1.9.1-1.9.1-2.6.3-4.7.9-6.4 1.8v-.9h-6.1v30.8h6.1v-21c0-1.1.5-2.2 1.3-2.8 1.4-1 3.6-1.6 6.7-1.8h.1c3.3.2 5.6.9 6.9 2.2.9 1 1.4 2.3 1.4 4v19.6h6.1V34.6c0-1.7.5-2.9 1.5-3.7 1.5-1.3 3.8-2 6.6-2.1h.5c3.4 0 5.9.8 7.3 2.2.9.9 1.4 2.1 1.4 3.9v19.6h6.1V34.9c.1-3.3-1-6.1-3.1-8.2z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgAilo;
