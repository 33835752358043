import { useState, useEffect } from 'react';

import CartStore from 'store/cart';

export const useCart = () => {
  const [cart, setCart] = useState([]);
  const [totals, setTotals] = useState({});

  useEffect(() => {
    const subscription = CartStore.state$.subscribe((state) => {
      setCart(state.cart);
      setTotals(state.totals);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const adjustItem = (id, quantity) => {
    CartStore.adjuctCartItem(id, quantity);
  };

  const removeItem = (item) => {
    CartStore.removeCartItem(item);
  };

  return {
    cart,
    totals,
    removeItem,
    adjustItem,
    emptyCart: CartStore.emptyCart,
  };
};

export default useCart;
