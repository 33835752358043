import React from 'react';

import {
  container,
  withPadding as withPaddingStyle,
} from './Container.module.scss';

const Container = ({
  children,
  withPadding = false,
  containerClass = false,
  ...rest
}) => {
  return (
    <div
      className={`${container}${withPadding ? ' ' + withPaddingStyle : ''} ${
        containerClass ? ' ' + containerClass : ''
      }`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Container;
