import * as React from 'react';

function DeliveryTruckStatic(props) {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={props.fill || '#000'}
        d="M448.584 194H360V64c0-5.522-4.478-10-10-10H10C4.478 54 0 58.478 0 64v57.172c0 5.522 4.478 10 10 10s10-4.478 10-10V74h108.765v104.333a10.001 10.001 0 0015.826 8.128l34.175-24.493 34.175 24.493a9.996 9.996 0 0010.393.768 10 10 0 005.432-8.896V74H340v272H20V211.167c0-5.522-4.478-10-10-10s-10 4.478-10 10V404c0 5.522 4.478 10 10 10h44.943c4.705 25.012 26.699 44 53.057 44s48.352-18.988 53.057-44h179.887c4.705 25.012 26.699 44 53.057 44s48.352-18.988 53.057-44H502c5.522 0 10-4.478 10-10V257.416C512 222.448 483.552 194 448.584 194zm-239.819-35.138l-24.175-17.326c-1.741-1.248-3.783-1.872-5.825-1.872s-4.084.624-5.825 1.872l-24.175 17.326V74h60zM468 302h24v24h-24zm-19.416-88C472.523 214 492 233.477 492 257.416V282h-88v-13.333c0-17.645-14.355-32-32-32-4.243 0-8.292.837-12 2.343V214zM360 268.667c0-6.617 5.383-12 12-12s12 5.383 12 12V282h-24zM340 394H161.057c-2.033-10.807-7.285-20.489-14.733-28H340zM20 366h49.676c-7.448 7.511-12.7 17.193-14.733 28H20zm88 72c-18.748 0-34-15.252-34-34s15.252-34 34-34 34 15.252 34 34-15.252 34-34 34zm286 0c-18.748 0-34-15.252-34-34s15.252-34 34-34 34 15.252 34 34-15.252 34-34 34zm53.057-44c-4.705-25.012-26.699-44-53.057-44-12.877 0-24.71 4.537-34 12.088V302h88v34c0 5.522 4.478 10 10 10h34v48z"
      />
      <path
        fill={props.fill || '#000'}
        d="M394 394c-2.63 0-5.21 1.069-7.07 2.93S384 401.37 384 404s1.069 5.21 2.93 7.069c1.86 1.86 4.44 2.931 7.07 2.931s5.21-1.07 7.069-2.931C402.93 409.21 404 406.63 404 404s-1.07-5.21-2.931-7.07A10.067 10.067 0 00394 394zm-286 0c-2.63 0-5.21 1.069-7.07 2.93S98 401.37 98 404s1.069 5.21 2.93 7.069c1.86 1.86 4.44 2.931 7.07 2.931s5.21-1.07 7.069-2.931C116.93 409.21 118 406.63 118 404s-1.07-5.21-2.931-7.07A10.067 10.067 0 00108 394zM68 278h84c5.522 0 10-4.478 10-10s-4.478-10-10-10H68c-5.522 0-10 4.478-10 10s4.478 10 10 10zm0 44h84c5.522 0 10-4.478 10-10s-4.478-10-10-10H68c-5.522 0-10 4.478-10 10s4.478 10 10 10zM10 176.17c2.63 0 5.21-1.07 7.069-2.93A10.077 10.077 0 0020 166.17c0-2.641-1.07-5.21-2.931-7.07-1.859-1.87-4.439-2.93-7.069-2.93s-5.21 1.06-7.07 2.93A10.042 10.042 0 000 166.17c0 2.63 1.069 5.21 2.93 7.07 1.86 1.86 4.44 2.93 7.07 2.93z"
      />
    </svg>
  );
}

export default DeliveryTruckStatic;
