import React from 'react';

import * as styles from './IconText.module.scss';

const IconText = ({
  icon,
  title = null,
  titleStyle = {},
  textStyle = {},
  iconStyle = {},
  text,
  iconSize = null,
  textSize = null,
  style = null,
}) => {
  return (
    <div style={style} className={styles.container}>
      <div
        className={`${styles.icon}${iconSize ? ' ' + styles[iconSize] : ''}`}
        style={iconStyle}
      >
        {icon}
      </div>
      <div className={styles.textContainer}>
        {title ? (
          <h1 className={styles.title} style={titleStyle}>
            {title}
          </h1>
        ) : null}
        <p
          className={`${styles.text}${textSize ? ' ' + styles[textSize] : ''}`}
          style={textStyle}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default IconText;
