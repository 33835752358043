import React from 'react';

import * as styles from './Circle.module.scss';

const Circle = ({ children, size = 'M', style = null }) => {
  return (
    <div
      data-testid="circle"
      className={`${styles['circle' + size]}`}
      style={style}
    >
      {children}
    </div>
  );
};

export default Circle;
