import React from 'react';

import { error } from './ErrorMessage.module.scss';

const ErrorMessage = ({ children, ...rest }) => {
  return (
    <p {...rest} className={error}>
      {children}
    </p>
  );
};

export default ErrorMessage;
